import PayGenFees from "../../pages/applicant/PayGenFees";
import $axios from "./config";

const studentApi = {
  async studentLogin(payload) {
    return $axios.post("/api/students/login", payload);
  },
  async logoutStudent() {
    return $axios.get("/api/students/logout");
  },
  async getStudentInformation() {
    return $axios.get("/api/students/info");
  },
  async forgotPassword(payload) {
    return $axios.post("/api/students/forgot", payload);
  },
  async resetPassword(payload) {
    return $axios.post("/api/students/reset", payload);
  },
  async getAllCourses() {
    return $axios.get("/api/students/courses/all");
  },

  async registerCourses(payload) {
    return $axios.post("/api/students/courses/register", payload);
  },

  async getAllCarryoverCourses() {
    return $axios.get("/api/students/courses/carryover/all");
  },

  async registerCarryoverCourses(payload) {
    return $axios.post("/api/students/courses/carryover/register", payload);
  },

  async updateProfilePicture(formData) {
    return $axios.post("/api/students/profile/picture", formData);
  },

  async updateProfile(payload) {
    return $axios.post("/api/students/profile/update", payload);
  },

  async getSessionByLevel(payload) {
    return $axios.get("/api/students/level/sessions", payload);
  },

  async getSessionResult(payload) {
    return $axios.get(`/api/students/session/result/${payload}`);
  },

  async getResultBySemester(payload) {
    return $axios.get(`/api/students/view/result/${payload}`);
  },
  async getCurrentResult() {
    return $axios.get(`/api/students/current/result`);
  },

  async getFeeType() {
    return $axios.get(`/api/students/semester/fees`);
  },

  async getFeeDetails(payload) {
    return $axios.get(`/api/students/transaction/${payload}`);
  },

  async getApplicationForm() {
    return $axios.get(`/api/students/application/form`);
  },

  async getAdmissionLetter() {
    return $axios.get(`/api/students/admission/letter`);
  },

  async getOutstandingFees() {
    return $axios.get(`/api/students/outstanding/fees`);
  },

  async getLatePenaltyFees() {
    return $axios.get("api/services/fee/late/penalty");
  },

  async getAssignedFees() {
    return $axios.get(`/api/students/assignment/fees`);
  },

  async payOutstandingFees(feeId, semId) {
    return $axios.get(
      `/api/students/outstanding/transaction/${feeId}/${semId}`
    );
  },

  async PayGenFees(Id) {
    return $axios.get(`/api/students/general/fees/pay/${Id}`);
  },

  async PayAssignedFees(Id) {
    return $axios.get(`/api/students/assignment/fees/pay/${Id}`);
  },

  async getPaymentHistory() {
    return $axios.get(`/api/students/payment/history`);
  },

  async getGeneralFees() {
    return $axios.get(`/api/students/general/fees`);
  },

  async getVerifyTransaction(payload) {
    return $axios.get(`/api/students/get/verify/transaction/${payload}`);
  },

  async postVerifyTransaction(payload) {
    return $axios.post(`/api/students/verify/transaction`, payload);
  },
  async getExamCard() {
    return $axios.get(`/api/students/exam/card`);
  },
  async getTuitionCheck() {
    return $axios.get(`/api/students/courses/register/activity`);
  },
  async getCarryoverCheck() {
    return $axios.get(`/api/students/courses/carryover/activity`);
  },
  async getOutstandingCheck() {
    return $axios.get(`api/students/semester/fees/activity`);
  },
  async getAllFeeTypes() {
    return $axios.get(`/api/students/fee/types/all`);
  },
  async getExamTimetable() {
    return $axios.get(`/api/students/exam/timetable`);
  },
};
export default studentApi;
