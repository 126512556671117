import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import AOS from "aos";
import "aos/dist/aos.css";

const AdeFees = ({ content }) => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);
  return (
    <div data-aos="fade-up">
      <Grid container spacing={4}>
        <Grid item md={5}>
          <table style={{ width: "100%" }}>
            <tr>
              <th className="table-head">Admission form</th>
              <th className="table-head">Semester 1</th>
              <th className="table-head">Semester 2</th>
            </tr>
            {content?.admissionFees?.map((fee, key) => (
              <tr key={key}>
                <td className="table-body">{fee?.admission_fee_name}</td>
                <td className="table-body">
                  {fee?.admission_fee_amount_first_semester}
                </td>
                <td className="table-body">
                  {fee?.admission_fee_amount_second_semester}
                </td>
              </tr>
            ))}
          </table>
        </Grid>
        <Grid item md={7}>
          <table style={{ width: "100%" }}>
            <tr>
              <th className="table-head">Other fees</th>
              <th className="table-head">Amount</th>
            </tr>

            {content?.otherFees?.map((fee, key = { key }) => (
              <tr key={key}>
                <td className="table-body">{fee?.name}</td>
                <td className="table-body">{fee?.amount}</td>
              </tr>
            ))}

            {/* <tr>
              <td className="table-body">
                Carry Over Charges for one/more papers
              </td>
              <td className="table-body">3,000</td>
            </tr> */}
          </table>
        </Grid>
      </Grid>
      <Grid item md={5} style={{ marginTop: "2rem" }}>
        <table style={{ width: "100%" }}>
          <tr>
            <th className="table-head">Tuition fees</th>
            <th className="table-head">Semester 1</th>
            <th className="table-head">Semester 2</th>
          </tr>
          {content?.tuitionFee?.map((fee, key = { key }) => (
            <tr key={key}>
              <td className="table-body">{fee?.tuition_fee_name}</td>
              <td className="table-body">
                {fee?.tuition_fee_amount_first_semester}
              </td>
              <td className="table-body">
                {fee?.tuition_fee_amount_second_semester}
              </td>
            </tr>
          ))}
        </table>
      </Grid>
    </div>
  );
};

const AdeeFees = ({ content }) => {
  return (
    <div data-aos="fade-up">
      <Grid container spacing={4}>
        <Grid item md={5}>
          <table style={{ width: "100%" }}>
            <tr>
              <th className="table-head">Admission form</th>
              <th className="table-head">Semester 1</th>
              <th className="table-head">Semester 2</th>
            </tr>
            {content?.admissionFees?.map((fee, key) => (
              <tr key={key}>
                <td className="table-body">{fee?.admission_fee_name}</td>
                <td className="table-body">
                  {fee?.admission_fee_amount_first_semester}
                </td>
                <td className="table-body">
                  {fee?.admission_fee_amount_second_semester}
                </td>
              </tr>
            ))}
          </table>
        </Grid>
        <Grid item md={7}>
          <table style={{ width: "100%" }}>
            <tr>
              <th className="table-head">Other fees</th>
              <th className="table-head">Amount</th>
            </tr>
            {content?.otherFees?.map((fee, key = { key }) => (
              <tr key={key}>
                <td className="table-body">{fee?.name}</td>
                <td className="table-body">{fee?.amount}</td>
              </tr>
            ))}
          </table>
        </Grid>
      </Grid>
      <Grid item md={5} style={{ marginTop: "2rem" }}>
        <table style={{ width: "100%" }}>
          <tr>
            <th className="table-head">Tuition fees</th>
            <th className="table-head">Semester 1</th>
            <th className="table-head">Semester 2</th>
          </tr>
          {content?.tuitionFee?.map((fee, key = { key }) => (
            <tr key={key}>
              <td className="table-body">{fee?.tuition_fee_name}</td>
              <td className="table-body">
                {fee?.tuition_fee_amount_first_semester}
              </td>
              <td className="table-body">
                {fee?.tuition_fee_amount_second_semester}
              </td>
            </tr>
          ))}
        </table>
      </Grid>
    </div>
  );
};

const BedFees = ({ content }) => {
  return (
    <div data-aos="fade-up">
      <Grid container spacing={4}>
        <Grid item md={5}>
          <table style={{ width: "100%" }}>
            <tr>
              <th className="table-head">Admission form</th>
              <th className="table-head">Semester 1</th>
              <th className="table-head">Semester 2</th>
            </tr>
            {content?.admissionFees?.map((fee, key) => (
              <tr key={key}>
                <td className="table-body">{fee?.admission_fee_name}</td>
                <td className="table-body">
                  {fee?.admission_fee_amount_first_semester}
                </td>
                <td className="table-body">
                  {fee?.admission_fee_amount_second_semester}
                </td>
              </tr>
            ))}
          </table>
        </Grid>
        <Grid item md={5}>
          <table style={{ width: "100%" }}>
            <tr>
              <th className="table-head">Tuition Fees</th>
              <th className="table-head">Semester 1</th>
              <th className="table-head">Semester 2</th>
            </tr>
            {content?.tutionFees?.map((fee, key = { key }) => (
              <tr key={key}>
                <td className="table-body">{fee?.name}</td>
                <td className="table-body">{fee?.amount_first_semester}</td>
                <td className="table-body">{fee?.amount_second_semester}</td>
              </tr>
            ))}
          </table>
        </Grid>
        <Grid item md={7}>
          <table style={{ width: "100%" }}>
            <tr>
              <th className="table-head">Other fees</th>
              <th className="table-head">Amount</th>
            </tr>
            {content?.otherFees?.map((fee, key = { key }) => (
              <tr key={key}>
                <td className="table-body">{fee?.name}</td>
                <td className="table-body">{fee?.amount}</td>
              </tr>
            ))}
          </table>
        </Grid>
      </Grid>
    </div>
  );
};

const NceFees = ({ content }) => {
  return (
    <div data-aos="fade-up">
      <Grid container spacing={4}>
        <Grid item md={5}>
          <table style={{ width: "100%" }}>
            <tr>
              <th className="table-head">Admission form</th>
              <th className="table-head">Semester 1</th>
              <th className="table-head">Semester 2</th>
            </tr>
            {content?.admissionFees?.map((fee, key) => (
              <tr key={key}>
                <td className="table-body">{fee?.admission_fee_name}</td>
                <td className="table-body">
                  {fee?.admission_fee_amount_first_semester}
                </td>
                <td className="table-body">
                  {fee?.admission_fee_amount_second_semester}
                </td>
              </tr>
            ))}
          </table>
        </Grid>
        <Grid item md={5}>
          <table style={{ width: "100%", marginBottom: "1rem" }}>
            <tr>
              <th className="table-head">NCE Cycle 1</th>
              <th className="table-head">Semester 1</th>
              <th className="table-head">Semester 2</th>
            </tr>
            {content?.nceCycle1?.map((fee, key) => (
              <tr key={key}>
                <td className="table-body">{fee?.name}</td>
                <td className="table-body">{fee?.semester_one}</td>
                <td className="table-body">{fee?.semester_two}</td>
              </tr>
            ))}
          </table>
          <table style={{ width: "100%", marginBottom: "1rem" }}>
            <tr>
              <th className="table-head">NCE Cycle 2</th>
              <th className="table-head">Semester 1</th>
              <th className="table-head">Semester 2</th>
            </tr>
            {content?.nceCycle2?.map((fee, key) => (
              <tr key={key}>
                <td className="table-body">{fee?.name}</td>
                <td className="table-body">{fee?.semester_one}</td>
                <td className="table-body">{fee?.semester_two}</td>
              </tr>
            ))}
            {/* <tr>
              <td className="table-body">Fee for Non-Practical Courses</td>
              <td className="table-body">23,000</td>
              <td className="table-body">17,000</td>
            </tr>
            <tr>
              <td className="table-body">
                Total for Practical Course ITS/PHE/CCA
              </td>
              <td className="table-body">26,000</td>
              <td className="table-body">20,000</td>
            </tr> */}
          </table>
          <table style={{ width: "100%", marginBottom: "1rem" }}>
            <tr>
              <th className="table-head">NCE Cycle 3</th>
              <th className="table-head">Semester 1</th>
              <th className="table-head">Semester 2</th>
            </tr>
            {content?.nceCycle3?.map((fee, key) => (
              <tr key={key}>
                <td className="table-body">{fee?.name}</td>
                <td className="table-body">{fee?.semester_one}</td>
                <td className="table-body">{fee?.semester_two}</td>
              </tr>
            ))}
            {/* <tr>
              <td className="table-body">Fee for Non-Practical Courses</td>
              <td className="table-body">17,000</td>
              <td className="table-body">17,000</td>
            </tr>
            <tr>
              <td className="table-body">
                {" "}
                Total for Practical Course ITS/PHE/CCA
              </td>
              <td className="table-body">20,000</td>
              <td className="table-body">20,000</td>
            </tr> */}
          </table>
          <table style={{ width: "100%", marginBottom: "1rem" }}>
            <tr>
              <th className="table-head">NCE Cycle 4</th>
              <th className="table-head">Semester 1</th>
              <th className="table-head">Semester 2</th>
            </tr>
            {content?.nceCycle4?.map((fee, key) => (
              <tr key={key}>
                <td className="table-body">{fee?.name}</td>
                <td className="table-body">{fee?.semester_one}</td>
                <td className="table-body">{fee?.semester_two}</td>
              </tr>
            ))}
            {/* <tr>
              <td className="table-body">Fee for Non-Practical Courses</td>
              <td className="table-body">23,000</td>
              <td className="table-body">17,000</td>
            </tr>
            <tr>
              <td className="table-body">
                Total for Practical Course ITS/PHE/CCA
              </td>
              <td className="table-body">26,000</td>
              <td className="table-body">20,000</td>
            </tr>  */}
          </table>
        </Grid>
        <Grid item md={7}>
          <table style={{ width: "100%", marginBottom: "1rem" }}>
            <tr>
              <th className="table-head">Other fees</th>
              <th className="table-head">Amount</th>
            </tr>
            {content?.otherFees?.map((fee, key) => (
              <tr key={key}>
                <td className="table-body">{fee?.name}</td>
                <td className="table-body">{fee?.amount}</td>
              </tr>
            ))}
            {/* <tr>
              <td className="table-body">
                Academic Transcript (Excluding Courier Cost)
              </td>
              <td className="table-body">2,000</td>
            </tr>
            <tr>
              <td className="table-body">
                Transfer, Change of Name/Change of Course (each)
              </td>
              <td className="table-body">1,000</td>
            </tr>
            <tr>
              <td className="table-body">Replacement of ID Card</td>
              <td className="table-body">1,000</td>
            </tr>
            <tr>
              <td className="table-body">Carry Over Charges</td>
              <td className="table-body">3,000</td>
            </tr>
            <tr>
              <td className="table-body">Late Registration Penalty Charges</td>
              <td className="table-body">4,000</td>
            </tr>
            <tr>
              <td className="table-body">Result Verification Charges</td>
              <td className="table-body">1,000</td>
            </tr> */}
          </table>
        </Grid>
      </Grid>
    </div>
  );
};

const PgdeFees = ({ content }) => {
  return (
    <div data-aos="fade-up">
      <Grid container spacing={4}>
        <Grid item md={5}>
          <table style={{ width: "100%", marginBottom: "2rem" }}>
            <tr>
              <th className="table-head">Admission form</th>
              <th className="table-head">Semester 1</th>
              <th className="table-head">Semester 2</th>
            </tr>
            {content?.admissionFees?.map((fee, key) => (
              <tr key={key}>
                <td className="table-body">{fee?.admission_fee_name}</td>
                <td className="table-body">
                  {fee?.admission_fee_amount_first_semester}
                </td>
                <td className="table-body">
                  {fee?.admission_fee_amount_second_semester}
                </td>
              </tr>
            ))}
          </table>
          <table style={{ width: "100%" }}>
            <tr>
              <th className="table-head">Tuition fees</th>
              <th className="table-head">Semester 1</th>
              <th className="table-head">Semester 2</th>
            </tr>
            {content?.tuitionFee?.map((fee, key = { key }) => (
              <tr key={key}>
                <td className="table-body">{fee?.tuition_fee_name}</td>
                <td className="table-body">
                  {fee?.tuition_fee_amount_first_semester}
                </td>
                <td className="table-body">
                  {fee?.tuition_fee_amount_second_semester}
                </td>
              </tr>
            ))}
            {/* <tr>
              <td className="table-body">Admission Form</td>
              <td className="table-body">56,000</td>
              <td className="table-body">35,000</td>
            </tr> */}
          </table>
        </Grid>
        <Grid item md={7}>
          <table style={{ width: "100%" }}>
            <tr>
              <th className="table-head">Other fees</th>
              <th className="table-head">Amount</th>
            </tr>
            {content?.otherFees?.map((fee, key = { key }) => (
              <tr key={key}>
                <td className="table-body">{fee?.name}</td>
                <td className="table-body">{fee?.amount}</td>
              </tr>
            ))}
          </table>
        </Grid>
      </Grid>
    </div>
  );
};

const PttpFees = ({ content }) => {
  return (
    <div data-aos="fade-up">
      <Grid container spacing={4}>
        <Grid item md={5}>
          <table style={{ width: "100%" }}>
            <tr>
              <th className="table-head">Admission form</th>
              <th className="table-head">Semester 1</th>
              <th className="table-head">Semester 2</th>
            </tr>
            {content?.admissionFees?.map((fee, key) => (
              <tr key={key}>
                <td className="table-body">{fee?.admission_fee_name}</td>
                <td className="table-body">
                  {fee?.admission_fee_amount_first_semester}
                </td>
                <td className="table-body">
                  {fee?.admission_fee_amount_second_semester}
                </td>
              </tr>
            ))}
          </table>
        </Grid>
        <Grid item md={7}>
          <table style={{ width: "100%" }}>
            <tr>
              <th className="table-head">Other fees</th>
              <th className="table-head">Amount</th>
            </tr>
            {content?.otherFees?.map((fee, key = { key }) => (
              <tr key={key}>
                <td className="table-body">{fee?.name}</td>
                <td className="table-body">{fee?.amount}</td>
              </tr>
            ))}
          </table>
        </Grid>
      </Grid>
      <Grid item md={5} style={{ marginTop: "2rem" }}>
        <table style={{ width: "100%" }}>
          <tr>
            <th className="table-head">Tuition fees</th>
            <th className="table-head">Semester 1</th>
            <th className="table-head">Semester 2</th>
          </tr>
          {content?.tuitionFee?.map((fee, key = { key }) => (
            <tr key={key}>
              <td className="table-body">{fee?.tuition_fee_name}</td>
              <td className="table-body">
                {fee?.tuition_fee_amount_first_semester}
              </td>
              <td className="table-body">
                {fee?.tuition_fee_amount_second_semester}
              </td>
            </tr>
          ))}
        </table>
      </Grid>
    </div>
  );
};

export { AdeFees, AdeeFees, BedFees, NceFees, PgdeFees, PttpFees };
