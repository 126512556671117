import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import { Grid } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import styles from "../../styles/webStyles";
import AOS from "aos";
import "aos/dist/aos.css";
import PlusIcon from "../../assets/images/plus-icon.svg";
import MinusIcon from "../../assets/images/minus-icon.svg";

const Faq = () => {
  const [clicked, setClicked] = useState(2);
  const toggler = (index) => {
    if (clicked === index) {
      setClicked(null);
    } else {
      setClicked(index);
    }
  };
  const sectionTwo = [
    {
      heading: "How to continue with your registration.",
      content: (
        <>
          <ul>
            <li>Type on your browser: my.nti.edu.ng</li>
            <li>Click on the BLUE icon (NEW STUDENT APPLY)</li>
            <li> Click on new application at top of your screen</li>
            <li> Type in your email address and password</li>
            <li>Click on login</li>
          </ul>
        </>
      ),
    },
    {
      heading: "How to generate RRR for Tuition fees (FIRST SEMESTER)?",
      content: (
        <>
          <ul>
            <li>On your browser type in my.nti.edu.ng</li>
            <li>Click on the “Current Student Apply” button</li>
            <li>Click on ‘Continue application’ at the top of your screen</li>
            <li> Type in your email address & password</li>
            <li>Click on the “pay tuition fee” button on your dashboard </li>
            <li>
              On the payment page, copy your RRR number or print/save the
              invoice.{" "}
            </li>
          </ul>
        </>
      ),
    },
    {
      heading: "How to generate RRR for Tuition fees (SECOND SEMESTER)",
      content: (
        <ul>
          <li>How to generate RRR for Tuition fee</li>
          <li>On your browser type in my.nti.edu.ng</li>
          <li>Click on the “Current Student Register” button </li>
          <li>
            Type in your student email address (see student email format in
            brackets: ( surname.firstname.my.nti.edu.ng) and password
          </li>
          <li>Click on the “My Fees” page </li>
          <li>Click on the “My Fees” page </li>

          <li>
            Click on the “Pay Now” button to generate your payment details,
            including your RRR
          </li>
        </ul>
      ),
    },
    {
      heading: " How to register for carryover courses.?",
      content: (
        <ul>
          <li>On your browser type: my.nti.edu.ng </li>

          <li>Click on the “Current student register” button. </li>

          <li>Type in your email and password </li>

          <li>Click on My Courses from the side menu item.</li>

          <li>Then click on the Carryover tab </li>

          <li>
            If payments have not been made, click on proceed to make the payment{" "}
          </li>

          <li>
            Select carryover fees from the “Fees Type” field and click on pay
            now.
          </li>

          <li>Generate your RRR </li>

          <li>Make your payment. </li>

          <li>Verify Payment</li>

          <li>
            Afterwards, you can register the carryover course(s) in the
            “Carryover courses” tab
          </li>
        </ul>
      ),
    },
    {
      heading: "How to access course materials?",
      content: (
        <ul>
          <li>On your browser type in my.nti.edu.ng </li>

          <li>click on the “Current student register” button </li>

          <li>Type in your student email address and password </li>

          <li>Click on my Courses menu item </li>

          <li>Then click on the Course materials tab </li>

          <p>
            N: B In other to access your course materials from your profile, you
            must have paid your tuition fees and registered your course{" "}
          </p>
        </ul>
      ),
    },
    {
      heading: "How to log in (forgot password) Applicant?",
      content: (
        <ul>
          <li>Type on your browser: my.nti.edu.ng </li>

          <li>Click on the BLUE button (New Student Apply) </li>

          <li>
            Click on “Continue application” at the top-right of your screen{" "}
          </li>

          <li>
            Click on “I forgot m User ID or Password” at the bottom of your
            login screen to reset your password{" "}
          </li>

          <li>Enter your Applicant Email & new password</li>
        </ul>
      ),
    },
    {
      heading: "How to log in( forgot password) Student ?",
      content: (
        <ul>
          <li>Type on your browser: my.nti.edu.ng </li>

          <li>Click on the green button (Current Student Register) </li>

          <li>
            Click on “I forgot m User ID or Password” at the bottom of your
            login screen to reset your password{" "}
          </li>

          <li>Enter your student email address </li>

          <li>And new password</li>
        </ul>
      ),
    },
  ];

  const sectionThree = [
    {
      heading: "How to access Results:?",
      content: (
        <ul>
          <li>On your browser type: my.nti.edu.ng </li>

          <li>Click on the current student register. </li>

          <li>Type in your student email address and password </li>

          <li>Click on Login </li>

          <li>Click on the My Results menu item from your Navigation </li>
        </ul>
      ),
    },
    {
      heading: "How to access Admission Letter/Application Form?",
      content: (
        <ul>
          <li>On your browser type: my.nti.edu.ng </li>

          <li>Click on the ”current student register” button. </li>

          <li>Type in your email address and password </li>

          <li>Click on Login </li>

          <li>
            Click on “My Documents” to download Admission Letter, Application
            Form
          </li>
        </ul>
      ),
    },
    {
      heading: "How to access Receipts:(STUDENT)",
      content: (
        <ul>
          <li>Visit the mynti students portal & login </li>

          <li>Click on My Fees from the side menu. </li>

          <li>
            Then click on the “Payment History” tab to access your Receipts{" "}
          </li>
        </ul>
      ),
    },
    {
      heading: "How to access Receipts:(Applicant)?",
      content: (
        <ul>
          <li>On your browser type: my.nti.edu.ng </li>

          <li>Click on the “new student apply” button </li>

          <li>
            Click the “continue application” button at the top-right of your
            screen{" "}
          </li>

          <li>Type in your email address and password </li>

          <li>Click on Login </li>

          <li>
            Click on the “My Documents” menu item from the Navigation, your
            receipts will be displayed
          </li>
        </ul>
      ),
    },
    {
      heading: "How to apply",
      content: (
        <ul>
          <li>On your browser type: my.nti.edu.ng </li>

          <li>Click on the “New Student Apply” button. </li>

          <li>Fill out the application form & submit</li>
        </ul>
      ),
    },
    {
      heading: "Where can I apply?",
      content: (
        <ul>
          <li>
            Kindly click on the link below to Apply:{" "}
            <a
              href="https://my.nti.edu.ng/applicants/apply"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://my.nti.edu.ng/applicants/apply
            </a>
          </li>
        </ul>
      ),
    },
    {
      heading:
        "Which email should I use to log in to the “Current Student Register” and “New student apply” page",
      content: (
        <ul>
          <li>Use your Personal email for “New Students Apply” </li>
          <li>Use NTI email for “Returning student”</li>
        </ul>
      ),
    },
  ];

  const sectionFour = [
    {
      heading: "How to print and register courses?",
      content: (
        <ul>
          <li>On your browser type in my.nti.edu.ng </li>

          <li>Click on the “current student register” button. </li>

          <li>
            Type in your student email address (surname.firstname.nti.edu.ng)
            and password{" "}
          </li>

          <li>Click on My courses. </li>

          <li>
            Select your Courses to register, by clicking on the checkboxes to
            select courses{" "}
          </li>

          <li>
            After Registration, click on “Download Course form” at the bottom of
            the page{" "}
          </li>
        </ul>
      ),
    },
    {
      heading: "How to Apply for a change of course? ",
      content: (
        <>
          <ul>
            <li>Kindly send a request to our contact centre</li>
          </ul>
        </>
      ),
    },
    {
      heading: " I want to know about a programme?",
      content: (
        <ul>
          <li>
            Kindly click on the link below to visit the programs page on our
            website{" "}
            <a
              href="https://my.nti.edu.ng/programs"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://my.nti.edu.ng/programs
            </a>
          </li>
        </ul>
      ),
    },

    {
      heading: " How to verify my RRR payment? Student ",
      content: (
        <ul>
          <li>On your browser type: my.nti.edu.ng </li>

          <li>Click on the “Current student register” button </li>

          <li>Type in your student email address and password </li>

          <li>Click on Login </li>

          <li>Click on my Fees page, the Fees form will be displayed </li>

          <li>
            Click on “If you have made payment via bank click here to verify
            your RRR” which is at the bottom of your screen.
          </li>

          <li>The “Verify your RRR” input field will be displayed.</li>
        </ul>
      ),
    },
    {
      heading: "How to generate RRR for Transcript fee/ Apply for Transcript ",
      content: (
        <>
          <ul>
            <li>On your browser type in my.nti.edu.ng </li>

            <li>Click on the current student register button </li>

            <li>Type in your student email address and password </li>

            <li>Click on My Fees, the Fees form will be displayed </li>

            <li>Select “Transcript Fee” from the “Fee Type” drop-down </li>

            <li>Click on pay now </li>

            <li>
              After the transcript fee payment contact your Centre manager/
              state coordinator for an update
            </li>
          </ul>
        </>
      ),
    },
    {
      heading: "How to generate certificate fee/ Apply for a Certificate ",
      content: (
        <>
          <ul>
            <li>On your browser type in my.nti.edu.ng </li>

            <li>Click on the “current student register” button. </li>

            <li>
              Type in your email address (in this format;
              surname.firstname@my.nti.edu.ng) and password{" "}
            </li>

            <li>Click on My fees, the Fees form will be displayed</li>

            <li>Select “Certificate Fee” from the “Fee Type” drop-down. </li>

            <li>
              After selecting the “Fee Type”, click on the “Save Invoice” button{" "}
            </li>

            <p>
              Note: After the certificate fee payment contact your Centre
              manager/ state coordinator for an update{" "}
            </p>
          </ul>
        </>
      ),
    },

    {
      heading: "What is the requirement for direct entry ",
      content: (
        <ul>
          <li>Diploma with merit in education </li>

          <li>Upper credit in Education. </li>

          <li>NCE with credit/5 c's/UTME</li>
        </ul>
      ),
    },
    {
      heading: "How do I continue the Application? ",
      content: (
        <ul>
          <li>Type in our website: my.nti.edu.ng </li>

          <li>Click on the “New student apply” button. </li>

          <li>
            Click on the “Continue application” button at the top-right of your
            screen{" "}
          </li>

          <li>Type in your email address and password </li>

          <li>Click on login</li>
        </ul>
      ),
    },
    {
      heading: "How do I access the learning management system: ",
      content: (
        <ul>
          <li>Kindly follow the steps below </li>
          <li>
            On your browser enter:{" "}
            <a
              href="https://www.lms.nti.edu.ng/ "
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.lms.nti.edu.ng/{" "}
            </a>{" "}
          </li>
          <li>
            Then enter your LMS login details assigned to you (you can find your
            login details on the student portal (profile page:{" "}
            <a
              href="https://my.nti.edu.ng/student/my-profile"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://my.nti.edu.ng/student/my-profile
            </a>{" "}
            ){" "}
          </li>
          OR
          <li>On your browser type: my.nti.edu.ng </li>
          <li>Click on the “Current student register” button </li>
          <li>Type in your student email address and password </li>
          <li>
            On your student dashboard, click on the “learning portal” button{" "}
          </li>
          <li>Enter the LMS login details provided to you</li>
        </ul>
      ),
    },
    {
      heading: "Is it possible to transfer to another study centre ",
      content: (
        <ul>
          <li>Yes, you can! Simply send a request to your contact centres</li>
        </ul>
      ),
    },

    {
      heading: "How much is the tuition fee for the NTI programmes",
      content: (
        <ul>
          <li style={{ paddingBottom: "24p" }}>
            <div className={`content ${clicked === 23 && "show"}`}>
              <p className="subHead">Tuition fee for all programs</p>
              <div className="support-list">
                <table className="fee-table-grid for-support">
                  <thead className="">
                    <tr>
                      <th>Programme</th>
                      <th>Level</th>
                      <th>1st Semester Tuition Fees</th>
                      <th>2nd Semester Tuition Fees</th>
                    </tr>
                  </thead>
                  <tbody className="applicant-doc-body ">
                    {/* first */}
                    <tr>
                      <td>
                        <p style={{ fontWeight: "bolder" }}>PGDE</p>
                      </td>
                      <td>
                        <p>100</p>
                      </td>
                      <td>
                        <p>₦77,000.00</p>
                      </td>
                      <td>
                        <p>₦55,000.00</p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4"></td>
                    </tr>
                    {/* Second */}
                    <tr>
                      <td rowspan="4">
                        <p style={{ fontWeight: "bolder" }}>B.ED</p>
                      </td>
                      <td>
                        <p>100</p>
                      </td>
                      <td>
                        <p>₦82,000.00</p>
                      </td>
                      <td>
                        <p>₦65,000.00</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>200</p>
                      </td>
                      <td>
                        <p>₦65,000.00</p>
                      </td>
                      <td>
                        <p>₦82,000.00</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>300</p>
                      </td>
                      <td>
                        <p>₦65,000.00</p>
                      </td>
                      <td>
                        <p>₦75,000.00</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>400</p>
                      </td>
                      <td>
                        <p>₦75,000 .00</p>
                      </td>
                      <td>
                        <p>₦75,000 .00</p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4"></td>
                    </tr>
                    {/* Third */}
                    <tr>
                      <td rowspan="10">
                        <p style={{ fontWeight: "bolder" }}>NCE</p>
                      </td>
                      <td colSpan="2">
                        <p style={{ fontWeight: "bolder" }}>
                          Fee for Non-Practical Courses
                        </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>100</p>
                      </td>
                      <td>
                        <p>₦ 28,000.00</p>
                      </td>
                      <td>
                        <p>₦23,800.00</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>200</p>
                      </td>
                      <td>
                        <p>₦32,200.00</p>
                      </td>
                      <td>
                        <p>₦23,800.00</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>300</p>
                      </td>
                      <td>
                        <p>₦32,200.00</p>
                      </td>
                      <td>
                        <p>₦32,200.00</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>400</p>
                      </td>
                      <td>
                        <p>₦23,000.00</p>
                      </td>
                      <td>
                        <p>₦23,000.00</p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <p style={{ fontWeight: "bolder" }}>
                          Fee for Practical Courses
                        </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>100</p>
                      </td>
                      <td>
                        <p>₦32,200.00</p>
                      </td>
                      <td>
                        <p>₦28,000.00</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>200</p>
                      </td>
                      <td>
                        <p>₦36,400.00</p>
                      </td>
                      <td>
                        <p>₦32,200.00</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>300</p>
                      </td>
                      <td>
                        <p>₦36,400.00</p>
                      </td>
                      <td>
                        <p>₦32,200.00</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>400</p>
                      </td>
                      <td>
                        <p>₦ 26,000.00</p>
                      </td>
                      <td>
                        <p>₦ 26,000.00</p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4"></td>
                    </tr>
                    {/* fourth */}
                    <tr>
                      <td>
                        <p style={{ fontWeight: "bolder" }}>PTTP</p>
                      </td>
                      <td>
                        <p>100</p>
                      </td>
                      <td>
                        <p>₦ 19,000.00</p>
                      </td>
                      <td>
                        <p>₦ 24,000.00</p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4"></td>
                    </tr>
                    {/* fifth */}
                    <tr>
                      <td>
                        <p style={{ fontWeight: "bolder" }}>ADE</p>
                      </td>
                      <td>
                        <p>100</p>
                      </td>
                      <td>
                        <p>₦ 39,000.00</p>
                      </td>
                      <td>
                        <p>₦ 38,000.00</p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4"></td>
                    </tr>
                    {/* sixth */}
                    <tr>
                      <td>
                        <p style={{ fontWeight: "bolder" }}>ADEE</p>
                      </td>
                      <td>
                        <p>100</p>
                      </td>
                      <td>
                        <p>₦ 39,000.00</p>
                      </td>
                      <td>
                        <p>₦ 38,000.00</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </li>
        </ul>
      ),
    },
  ];

  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  const history = useHistory();
  const [expandedAccordions, setExpandedAccordions] = React.useState([]);

  const handleChange = (key) => {
    if (expandedAccordions.indexOf(key) > -1) {
      setExpandedAccordions(expandedAccordions.filter((exp) => exp !== key));
    } else {
      setExpandedAccordions([...expandedAccordions, key]);
    }
  };

  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);
  return (
    <div style={{ backgroundColor: "rgba(246, 246, 246, 1)" }}>
      <div data-aos="fade-up" className="container content">
        <h1
        // style={{ margin: "4% 0 0 11%" }}
        >
          FAQ
        </h1>
        <div className="accordion-grid">
          <div data-aos="fade-up" className="accordion-item">
            {sectionTwo.map((items, index) => {
              return (
                <Accordion
                  className="accordion-section"
                  style={{ margin: "0.5rem 0" }}
                  key={index}
                  defaultExpanded={false}
                  onChange={() => handleChange(index)}
                >
                  <AccordionSummary
                    expandIcon={
                      expandedAccordions.indexOf(index) > -1 ? (
                        <RemoveIcon className="icon-color" />
                      ) : (
                        <AddIcon className="icon-color" />
                      )
                    }
                  >
                    <h4>{items.heading}</h4>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{items.content}</Typography>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
          <div data-aos="fade-up" className="accordion-item">
            {sectionThree.map((items, index) => {
              return (
                <Accordion
                  className="accordion-section"
                  style={{ margin: "0.5rem 0" }}
                  key={index}
                  defaultExpanded={false}
                  onChange={() => handleChange(index)}
                >
                  <AccordionSummary
                    expandIcon={
                      expandedAccordions.indexOf(index) > -1 ? (
                        <RemoveIcon className="icon-color" />
                      ) : (
                        <AddIcon className="icon-color" />
                      )
                    }
                  >
                    <h4>{items.heading}</h4>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{items.content}</Typography>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
          <div data-aos="fade-up" className="accordion-item">
            {sectionFour.map((items, index) => {
              return (
                <Accordion
                  className="accordion-section"
                  style={{ margin: "0.5rem 0" }}
                  key={index}
                  defaultExpanded={false}
                  onChange={() => handleChange(index)}
                >
                  <AccordionSummary
                    expandIcon={
                      expandedAccordions.indexOf(index) > -1 ? (
                        <RemoveIcon className="icon-color" />
                      ) : (
                        <AddIcon className="icon-color" />
                      )
                    }
                  >
                    <h4>{items.heading}</h4>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{items.content}</Typography>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Faq;
